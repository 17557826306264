import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const setupGsapAnimations = (config) => {
    gsap.from(".title", {
        duration: config.gsapTitleDuration,
        opacity: 0,
        y: 2,
        filter: 'blur(10px)',
        ease: config.gsapEase,
        onComplete: function () {
            gsap.to(".title", { filter: 'blur(0)', duration: 0.3 });
        }
    });

    gsap.from(".subtitle", {
        duration: config.gsapTitleDuration,
        opacity: 0,
        y: 10,
        delay: config.gsapSubtitleDelay,
        filter: 'blur(10px)',
        ease: config.gsapEase,
        onComplete: function () {
            gsap.to(".subtitle", { filter: 'blur(0)', duration: 0.3 });
        }
    });

    gsap.utils.toArray('.section').forEach((section, index) => {
        gsap.from(section, {
            duration: config.gsapTitleDuration,
            opacity: 0,
            y: 50,
            filter: 'blur(10px)',
            ease: config.gsapEase,
            scrollTrigger: {
                trigger: section,
                start: config.scrollTriggerStart,
                end: config.scrollTriggerEnd,
                toggleActions: "play none none none",
                once: true,
            },
            onComplete: function () {
                gsap.to(section, { filter: 'blur(0)', duration: 0.5 });
            }
        });
    });
};
