import React, { Component, createRef } from 'react';
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';
import backgroundConfig from './backgroundConfig';

class Background extends Component {
    constructor(props) {
        super(props);
        this.mountRef = createRef();
        this.state = {
            isLoaded: false
        };
    }

    componentDidMount() {
        const { camera, renderer, planeGeometry, shaderMaterial, animation, blurredElements } = backgroundConfig;
        const currentMount = this.mountRef.current;

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(camera.fov, camera.aspect, camera.near, camera.far);
        this.camera.position.z = camera.positionZ;

        this.renderer = new THREE.WebGLRenderer({ antialias: renderer.antialias });
        this.renderer.setSize(renderer.width, renderer.height);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        currentMount.appendChild(this.renderer.domElement);

        const geometry = new THREE.PlaneGeometry(planeGeometry.width, planeGeometry.height, planeGeometry.widthSegments, planeGeometry.heightSegments);
        this.material = new THREE.ShaderMaterial(shaderMaterial);
        const plane = new THREE.Mesh(geometry, this.material);
        this.scene.add(plane);

        this.blurredElements = [];
        const glassMaterial = new THREE.MeshPhysicalMaterial({
            color: new THREE.Color(blurredElements.color),
            metalness: 0.5,
            roughness: 0.1,
            transmission: 0.5,
            transparent: true,
            opacity: 0.5
        });

        for (let i = 0; i < blurredElements.count; i++) {
            const blurGeometry = new THREE.SphereGeometry(Math.random() * blurredElements.size + 0.1, 32, 32);
            const blurElement = new THREE.Mesh(blurGeometry, glassMaterial);
            blurElement.position.set(
                (Math.random() - 0.5) * planeGeometry.width,
                (Math.random() - 0.3) * planeGeometry.height,
                (Math.random() - 0.5) * 2
            );
            blurElement.scale.setScalar(Math.random() * blurredElements.size + 0.10);
            this.scene.add(blurElement);
            this.blurredElements.push(blurElement);
        }

        // Post-processing
        this.composer = new EffectComposer(this.renderer);
        this.composer.addPass(new RenderPass(this.scene, this.camera));

        // FXAA pass for anti-aliasing
        const fxaaPass = new ShaderPass(FXAAShader);
        const pixelRatio = this.renderer.getPixelRatio();
        fxaaPass.material.uniforms['resolution'].value.x = 1 / (window.innerWidth * pixelRatio);
        fxaaPass.material.uniforms['resolution'].value.y = 1 / (window.innerHeight * pixelRatio);
        this.composer.addPass(fxaaPass);

        // Unreal Bloom Pass
        const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
        bloomPass.threshold = 0;
        bloomPass.strength = 0.5; // Bloom strength
        bloomPass.radius = 0.5;
        this.composer.addPass(bloomPass);

        window.addEventListener('resize', this.handleResize);

        this.setState({ isLoaded: true }); // Update state to indicate loading is complete
        this.startAnimation();
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.frameId);
        window.removeEventListener('resize', this.handleResize);
        this.mountRef.current.removeChild(this.renderer.domElement);
    }

    handleResize = () => {
        const { camera, renderer } = backgroundConfig;
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.composer.setSize(window.innerWidth, window.innerHeight);
    };

    startAnimation = () => {
        this.frameId = requestAnimationFrame(this.animate);
    };

    animate = () => {
        const { animation, blurredElements } = backgroundConfig;
        this.material.uniforms.time.value += animation.speed;
        this.blurredElements.forEach((element, index) => {
            element.position.x += Math.sin(this.material.uniforms.time.value * (index + 1)) * blurredElements.speed;
            element.position.y += Math.cos(this.material.uniforms.time.value * (index + 1)) * blurredElements.speed;
            element.position.z += Math.sin(this.material.uniforms.time.value * (index + 1)) * blurredElements.speed * 0.5;
            element.material.opacity = 0.3 + 0.2 * Math.sin(this.material.uniforms.time.value + index); // Vary opacity
        });
        this.composer.render();
        this.frameId = requestAnimationFrame(this.animate);
    };

    render() {
        const { isLoaded } = this.state;
        return (
            <div
                ref={this.mountRef}
                style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}
                className={isLoaded ? '' : 'blurred-background'} // Apply blur class conditionally
            />
        );
    }
}

export default Background;
