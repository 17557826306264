// app/views/homes/Index.jsx

import React from 'react';
import {
    Github,
    Instagram,
    Linkedin,
    Rocket
} from 'lucide-react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { sections } from './indexSectionContent';
import ContributionsGraph from './ContributionsGraph';
import Modal from './Modal';
import Background from './Background';
import Section from './Section';
import { initializeAnimations } from './setupAnimations';
import config from './indexAnimationConfig';
import gsap from 'gsap';
import { Turbo } from '@hotwired/turbo-rails';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "Joonas",
            jobTitle: "Backend Developer",
            modalVisible: false,
            modalContent: null,
            selectedYear: 2024,
            animationDuration: 0.04,
            staggerInterval: 0.007,
            selectedCategory: 'Spatial Data',
            totalContributions: 0,
            blurClass: 'blurred',
        };
    }

    componentDidMount() {
        initializeAnimations(config);
        this.blurTimeout = setTimeout(() => this.setState({ blurClass: '' }), 500);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedYear !== this.state.selectedYear) {
            this.setState({ blurClass: 'blurred' });
            clearTimeout(this.blurTimeout);
            this.blurTimeout = setTimeout(() => this.setState({ blurClass: '' }), 500);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.blurTimeout);
    }

    toggleYear = (year) => {
        this.setState({ selectedYear: year });
    }

    openModal = (content) => {
        this.setState({ modalVisible: true, modalContent: content });
    };

    closeModal = () => {
        this.setState({ modalVisible: false, modalContent: null });
    };

    handleCategorySelection = (category) => {
        const modalContent = {
            title: category.name,
            content: `Details and content about ${category.name}`,
            subtitle: "Additional Information",
            subcontent: "More details about the category."
        };
        this.openModal(modalContent);
    };

    updateTotalContributions = (totalContributions) => {
        this.setState({ totalContributions });
    };

    renderSections() {
        const { blurClass } = this.state;
        return sections.map((section, index) => (
            <Section
                key={index}
                section={section}
                blurClass={blurClass}
                openModal={this.openModal}
                icons={{ Rocket }}
            />
        ));
    }

    renderMainView() {
        const {
            name,
            jobTitle,
            selectedYear,
            animationDuration,
            staggerInterval,
            totalContributions,
            blurClass
        } = this.state;

        const categories = [

        ];

        return (
            <>
                <header className={`header`}>
                    <h1 className={`title overline-text ${blurClass}`}>
                        <span>{name}</span>
                    </h1>
                    <h2 className={`subtitle overline-text ${blurClass}`}>
                        <span>{jobTitle}</span>
                    </h2>
                </header>
                <main className="main-content">
                    <fieldset className={`legend ${blurClass}`}>
                        <div className="total-contributions">
                            <Github size={24} className="github-icon" />
                            <h2><strong>{totalContributions}</strong></h2>
                            <h3>Contributions in {selectedYear}</h3>
                        </div>
                        <div className="year-toggle-buttons">
                            <button
                                className={selectedYear === 2023 ? 'active' : ''}
                                onClick={() => selectedYear !== 2023 && this.toggleYear(2023)}
                            >
                                2023
                            </button>
                            <button
                                className={selectedYear === 2024 ? 'active' : ''}
                                onClick={() => selectedYear !== 2024 && this.toggleYear(2024)}
                            >
                                2024
                            </button>
                        </div>
                    </fieldset>
                    <ContributionsGraph
                        year={selectedYear}
                        animationDuration={animationDuration}
                        staggerInterval={staggerInterval}
                        onUpdateTotalContributions={this.updateTotalContributions}
                    />
                    <nav className="navigation-menu">
                        <ul>
                            {categories.map((category, index) => (
                                <li key={index}>
                                    <Link to={category.path} className="nav-link">
                                        {category.icon} {category.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    {/* {this.renderSections()} */}
                </main>
            </>
        );
    }

    renderCategoryView = () => {
        const { match } = this.props;
        const categoryName = match ? match.params.categoryName : '';
        const currentCategory = {
            name: categoryName,
            icon: <Rocket size={24} />
        };

        return (
            <div className="category-view">
                <Link to="/" className="back-button">Back</Link>
                <h1>{currentCategory.name}</h1>
                {currentCategory.icon}
            </div>
        );
    }

    render() {
        const { modalVisible, modalContent, name } = this.state;

        return (
            <Router>
                <div className="portfolio-container">
                    <Background />
                    <div className="custom-cursor"></div>
                    <Routes>
                        <Route exact path="/" element={this.renderMainView()} />
                        <Route path="/:categoryName" element={this.renderCategoryView} />
                    </Routes>
                    <footer className="footer">
                        <p>&copy; {new Date().getFullYear()} {name}</p>
                        <div className="social-icons">
                            <a href="https://www.instagram.com/vyhtine/" target="_blank" rel="noopener noreferrer" className="social-link" aria-label="Instagram">
                                <Instagram size={24} />
                            </a>
                            {/* Removed TikTok link */}
                            <a href="https://www.linkedin.com/in/joonas-vyhtinen-b3a14a1b6/" target="_blank" rel="noopener noreferrer" className="social-link" aria-label="LinkedIn">
                                <Linkedin size={24} />
                            </a>
                            <a href="https://github.com/FloatingAnxiety" target="_blank" rel="noopener noreferrer" className="social-link" aria-label="GitHub">
                                <Github size={24} />
                            </a>
                        </div>
                    </footer>
                    <Modal
                        visible={modalVisible}
                        content={modalContent}
                        onClose={this.closeModal}
                    />
                </div>
            </Router>
        );
    }
}

export default Index;
