// Importing necessary modules
import { Application } from "@hotwired/stimulus";

// Function to create a regular expression for matching controller filenames
export function controllerFilenameRegexp(rootPaths = ["controllers", "components", "views"]) {
    // eslint-disable-next-line security/detect-unsafe-regex, security/detect-non-literal-regexp
    return new RegExp(
        `^(?:.*?(?:${rootPaths.join("|")})/|.?./)?(.+)(?:[/_-]controller..+?)$`
    );
}

// Function to register controllers with the Stimulus application
export function registerControllers(application, controllerModules) {
    application.load(definitionsFromGlob(controllerModules));
}

// Function to create controller definitions from imported modules
export function definitionsFromGlob(controllerModules) {
    return Object.entries(controllerModules)
        .map(definitionFromEntry)
        .filter((value) => value);
}

// Function to create a controller definition from an entry
function definitionFromEntry([name, controllerModule]) {
    const identifier = identifierForGlobKey(name);
    const controllerConstructor = controllerModule.default;
    if (identifier && typeof controllerConstructor === "function") {
        return { identifier, controllerConstructor };
    }
}

// Function to generate a Stimulus identifier from a glob key
export function identifierForGlobKey(key) {
    const filenameRegexp = controllerFilenameRegexp();
    const logicalName = (key.match(filenameRegexp) || [])[1];
    if (logicalName) {
        return logicalName.replace(/[^\p{L}\p{N}]+/gu, "-");
    }
}
