import { setupCursor } from './indexCursor';
import { setupGsapAnimations } from './indexGsapAnimations';
import { setupButtonAnimations } from './buttonAnimations';
import { setupTextTypingAnimation } from './textTypingAnimation';

export function initializeAnimations(config) {
    setupCursor(config);
    setupGsapAnimations(config);
    setupButtonAnimations('button');
    setupTextTypingAnimation();
}
