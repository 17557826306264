import React, { Component } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import * as d3 from 'd3';
import gsap from 'gsap';

class ContributionsGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contributions: [],
            loading: true,
        };
        this.svgRef = React.createRef();
    }

    componentDidMount() {
        this.fetchContributions(this.props.year);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.year !== this.props.year) {
            this.fetchContributions(this.props.year);
        }
    }

    async fetchContributions(year) {
        this.setState({ loading: true });

        try {
            const response = await axios.get(`/contributions_${year}`);
            const { contributions, [`total_contributions_${year}`]: totalContributions } = response.data;

            localStorage.setItem(`contributions_${year}`, JSON.stringify(contributions));
            localStorage.setItem(`total_contributions_${year}`, JSON.stringify(totalContributions));

            this.props.onUpdateTotalContributions(totalContributions);

            this.setState({
                contributions: Object.entries(contributions).map(([date, count]) => ({ date: new Date(date), count })),
                loading: false
            }, this.renderContributionGraph);
        } catch (error) {
            console.error('Error fetching contributions:', error);
            this.setState({ loading: false });
        }
    }

    renderContributionGraph() {
        const { contributions } = this.state;
        const { year, animationDuration, staggerInterval } = this.props;
        const yearStart = new Date(`${year}-01-01`);
        const yearEnd = new Date(`${year}-12-31`);
        const yearWeeks = d3.timeWeeks(d3.timeSunday.floor(yearStart), d3.timeSunday.ceil(yearEnd));

        const cellSize = 8; // Size of each cell
        const weekWidth = cellSize + 2; // Width of each week cell including padding
        const svgWidth = yearWeeks.length * weekWidth; // Total width of the SVG
        const svgHeight = 7 * weekWidth + 20; // Total height of the SVG

        const contributionMap = new Map(contributions.map(d => [d3.timeFormat('%Y-%m-%d')(d.date), d.count]));
        const maxCount = d3.max(contributions, d => d.count);

        const colorScale = d3.scaleThreshold()
            .domain([1, maxCount * 0.25, maxCount * 0.5, maxCount * 0.75, maxCount])
            .range([
                'contribution-level-0',  // 0 contributions
                'contribution-level-1',  // 1 contribution
                'contribution-level-2',  // low range
                'contribution-level-3',  // mid range
                'contribution-level-4',  // mid-high range
                'contribution-level-5'   // high range
            ]);

        const svg = d3.select(this.svgRef.current)
            .attr("viewBox", `0 0 ${svgWidth} ${svgHeight}`)
            .attr("class", "contribution-svg");

        svg.selectAll("*").remove();

        const cells = svg.append("g")
            .selectAll("rect")
            .data(d3.timeDays(yearStart, yearEnd))
            .enter().append("rect")
            .attr("class", d => {
                const count = contributionMap.get(d3.timeFormat('%Y-%m-%d')(d)) || 0;
                return `contribution-cell ${colorScale(count)}`;
            })
            .attr("width", cellSize)
            .attr("height", cellSize)
            .attr("x", d => d3.timeWeek.count(d3.timeYear(d), d) * weekWidth)
            .attr("y", d => d.getDay() * weekWidth + 20)
            .style("opacity", d => (contributionMap.has(d3.timeFormat('%Y-%m-%d')(d)) && contributionMap.get(d3.timeFormat('%Y-%m-%d')(d)) > 0) ? 0 : 1)
            .append("title")
            .text(d => `${d.toDateString()}: ${contributionMap.get(d3.timeFormat('%Y-%m-%d')(d)) || 0}`);

        svg.append("g")
            .attr("transform", "translate(0, -5)")
            .selectAll("text")
            .data(d3.timeMonths(yearStart, yearEnd))
            .enter().append("text")
            .attr("x", d => d3.timeWeek.count(d3.timeYear(d), d) * weekWidth)
            .attr("y", 10)
            .attr("dy", "0.32em")
            .text(d => d3.timeFormat("%b")(d));

        // Animate only the cells with contributions greater than 0
        gsap.to(svg.selectAll("rect").filter(d => (contributionMap.get(d3.timeFormat('%Y-%m-%d')(d)) || 0) > 0).nodes(), {
            duration: animationDuration,
            opacity: 1,
            stagger: {
                each: staggerInterval,
                from: "random"
            },
            ease: "power1.inOut"
        });
    }

    render() {
        const { loading } = this.state;

        return (
            <div id="contribution-graph" className={`contribution-graph ${loading ? 'blurred' : ''}`}>
                <div className="contributions">
                    {loading ? <Skeleton count={53 * 7}/> :
                        <svg ref={this.svgRef} className="contribution-svg"></svg>}
                </div>
                <p>{this.props.year} daily GitHub contributions</p>
            </div>
        );
    }
}

export default ContributionsGraph;
