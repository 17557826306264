const config = {
    animationDuration: 0.04,
    staggerInterval: 0.007,
    cursorOffset: 10,
    glitchTextFrequency: 0.02,
    glitchTextDuration: 50,
    glitchTextDelay: 50,
    glitchEffectFrequency: 30000,
    frameTearChance: 0.002,
    frameTearWidth: 50,
    gsapTitleDuration: 1.5,
    gsapSubtitleDelay: 0.5,
    gsapSectionStagger: 0.2,
    gsapEase: "power3.out",
    scrollTriggerStart: "top 75%",
    scrollTriggerEnd: "bottom 25%",
    timeScale: 0.1,
    glitchThreshold: 0.01,
    glitchIntensity: 0.01,
    colorGlitchThreshold: 0.0001,
    glitchTransparency: 0.9,
    glitchCooldown: 2000,

    // New properties for the contribution chart
    contributionCellSize: 17,
    contributionLabelSize: 12,
    contributionCellCountPerColumn: 10
};

export default config;
