export const setupCursor = (config) => {
    const cursor = document.querySelector('.custom-cursor');

    const isMobile = () => {
        return window.matchMedia("(pointer: coarse)").matches;
    };

    if (isMobile()) {
        cursor.style.display = 'none';
    } else {
        cursor.style.display = 'block';

        document.addEventListener('mousemove', e => {
            cursor.style.left = `${e.clientX - config.cursorOffset}px`;
            cursor.style.top = `${e.clientY - config.cursorOffset}px`;
        });

        document.querySelectorAll('.hover-target').forEach(item => {
            item.addEventListener('mouseenter', () => cursor.classList.add('hovered'));
            item.addEventListener('mouseleave', () => cursor.classList.remove('hovered'));
        });

        document.querySelectorAll('button').forEach(button => {
            button.addEventListener('mouseenter', () => {
                cursor.classList.add('button-hovered');
                cursor.classList.remove('hovered');
            });
            button.addEventListener('mouseleave', () => cursor.classList.remove('button-hovered'));
        });

        document.querySelectorAll('.navigation-menu li').forEach(item => {
            item.addEventListener('mouseenter', () => {
                cursor.classList.add('nav-hovered');
                cursor.classList.remove('hovered');
                cursor.classList.remove('button-hovered');
            });
            item.addEventListener('mouseleave', () => cursor.classList.remove('nav-hovered'));
        });
    }
};

document.addEventListener('DOMContentLoaded', () => {
    setupCursor({ cursorOffset: 10 });
});
