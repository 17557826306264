import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Section extends Component {
    handleClick = () => {
        const { section, openModal } = this.props;
        openModal(section);
    }

    render() {
        const { section, blurClass, icons } = this.props;
        return (
            <section className={`section hover-target ${blurClass}`} onClick={this.handleClick}>
                <div className="overline-text">
                    <h3>
                        <FontAwesomeIcon icon={icons[section.icon]} /> <span>{section.title}</span>
                    </h3>
                    <span className="plain-text">{section.content}</span>
                </div>
            </section>
        );
    }
}

export default Section;
