import * as THREE from 'three';

const backgroundConfig = {
    camera: {
        fov: 20,
        aspect: window.innerWidth / window.innerHeight,
        near: 0.1,
        far: 1000,
        positionZ: 5
    },
    renderer: {
        antialias: true,
        width: window.innerWidth,
        height: window.innerHeight
    },
    planeGeometry: {
        width: 5,
        height: 10,
        widthSegments: 32,
        heightSegments: 32
    },
    animation: {
        speed: 0.0002
    },
    shaderMaterial: {
        uniforms: {
            time: { value: 1.0 },
            resolution: { value: new THREE.Vector2() },
            color1: { value: new THREE.Color('#0d0a17') }, // Darker cool color
            color2: { value: new THREE.Color('#011715') }  // Darker cool color
        },
        vertexShader: `
            varying vec2 vUv;
            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `,
        fragmentShader: `
            uniform float time;
            uniform vec3 color1;
            uniform vec3 color2;
            varying vec2 vUv;
            void main() {
                vec3 color = mix(color1, color2, vUv.y + 0.5 * sin(time * 0.1 + vUv.x * 3.14) * 0.5 + cos(time * 0.15 + vUv.y * 3.14) * 0.5);
                gl_FragColor = vec4(color, 1.0);
            }
        `
    },
    blurredElements: {
        count: 10, // More blurred elements
        speed: 0.0003, // Slower speed for blurred elements
        color: '#7f8c8d', // Darker, subtler color for blurred elements
        size: 0.3, // Larger size for better visibility and variation
        blurAmount: 0.2 // Increased blur amount
    }
};

export default backgroundConfig;
