import React from 'react';
import { createRoot } from 'react-dom/client';
import { Controller } from "@hotwired/stimulus";
import Index from './Index.jsx';

export default class extends Controller {
    static targets = ["main"];

    connect() {
        console.log("Connected to index controller");
        const references = this.fetchReferences(); // Assuming you have a method to fetch references
        this.renderIndex(references);
    }

    fetchReferences() {
        // Fetch or get references data, this could be an API call or props passed to the Stimulus controller
        return [
            // Example data
            { id: 1, title: 'Project 1', description: 'Description 1' },
            { id: 2, title: 'Project 2', description: 'Description 2' }
        ];
    }

    renderIndex(references) {
        const root = createRoot(this.mainTarget);
        root.render(<Index references={references} />);
    }
}
