// app/javascript/packs/application.js
import * as Turbo from '@hotwired/turbo';
Turbo.start();

import { Application } from "@hotwired/stimulus";
const application = Application.start();

import { registerControllers } from "../lib/stimulus_vite_helpers";
const controllerModules = import.meta.glob('../../views/**/*_controller.{js,jsx}', { eager: true });
registerControllers(application, controllerModules);

document.addEventListener('turbo:visit', () => {
    document.body.classList.add('morph-leave');
});

document.addEventListener('turbo:before-cache', () => {
    document.body.classList.remove('morph-leave');
    document.body.classList.add('morph-enter');
});

document.addEventListener('turbo:load', () => {
    document.body.classList.remove('morph-enter');
    requestAnimationFrame(() => {
        document.body.classList.remove('morph-leave');
        document.body.classList.remove('morph-enter');
    });
});

document.addEventListener('turbo:load', () => {
    const handleLoadAnimation = () => {
        document.body.classList.remove('morph-leave');
        document.body.classList.add('morph-enter');
        setTimeout(() => {
            document.body.classList.remove('morph-enter');
        }, 500); // Match the duration of the CSS transition
    };

    const handleExitAnimation = () => {
        document.body.classList.add('morph-leave');
    };

    const resetStyles = () => {
        document.body.classList.remove('morph-leave');
        document.body.classList.remove('morph-enter');
    };

    handleLoadAnimation(); // Initial load animation
    document.addEventListener('turbo:load', handleLoadAnimation);
    document.addEventListener('turbo:before-visit', handleExitAnimation);
    document.addEventListener('turbo:before-cache', resetStyles);
});

export { application };
