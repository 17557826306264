import gsap from 'gsap';

// Configuration object
const config = {
    pSpeed: 0.3,
    spanSpeed: 0.3
};

export const setupTextTypingAnimation = () => {
    const elements = document.querySelectorAll('p, span');

    const createSpans = (text) => {
        return text.split(/(\s+)/).map(word => {
            const wordSpan = document.createElement('span');
            wordSpan.style.whiteSpace = 'pre'; // Ensure spaces are preserved
            wordSpan.textContent = word;
            return wordSpan;
        });
    };

    const animateText = (element, speed) => {
        const spans = createSpans(element.textContent);
        element.textContent = ''; // Clear the element's text content

        spans.forEach(span => element.appendChild(span));

        gsap.to(spans, {
            duration: speed,
            opacity: 1,
            stagger: {
                each: speed * 0.2
            }
        });
    };

    const observerOptions = {
        root: null,
        rootMargin: '40px',
        threshold: 1
    };

    const observerCallback = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const element = entry.target;
                const speed = element.tagName.toLowerCase() === 'p' ? config.pSpeed : config.spanSpeed;
                animateText(element, speed);
                observer.unobserve(element);
            }
        });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    elements.forEach(element => {
        observer.observe(element);
    });
};
