export const sections = [
    {/*
    {
        icon: "faBolt",
        title: "I do what others won't",
        content: "I build what others can't. I commit when others don't. It is my mission to become the best of the best.",
        subcontent: "Blending creativity and tech expertise to build the backbone of innovative digital solutions."
    },
    {
        icon: "faFire",
        title: "Fearless Innovator",
        content: "I thrive on challenges and bold ideas. Playing it safe isn't my style. I push boundaries and take risks.",
        subtitle: "Embracing Bold Ideas",
        subcontent: "Pushing boundaries and seeking innovative solutions others shy away from."
    },
    {
        icon: "faAnchor",
        title: "Teams Built on Trust",
        content: "I value people for their unique strengths.",
        subtitle: "Embracing Diversity",
        subcontent: "Every person’s strengths contribute to the team's success."
    },
    {
        icon: "faHeartbeat",
        title: "Startups: Continuous Struggle",
        content: "Startups face constant struggles. Resilient teams overcome them. Good company makes it manageable.",
        subtitle: "Startup Struggle",
        subcontent: "Resilience and teamwork are essential in startups."
    },
    {
        icon: "faGem",
        title: "Innovation and Quality",
        content: "My projects reflect high standards and innovative thinking. I exceed industry norms.",
        subtitle: "Exceeding Expectations",
        subcontent: "Delivering functional and elegant solutions."
    },
    {
        icon: "faShieldAlt",
        title: "Digital Defense",
        content: "I safeguard digital assets with precision, ensuring systems are resilient and secure.",
        subtitle: "Proactive Security",
        subcontent: "Mitigating threats before they become issues."
    },
    {
        icon: "faCodeBranch",
        title: "Excellence in Code",
        content: "I leverage advanced technologies to craft solutions that exceed industry standards.",
        subtitle: "Committed to Perfection",
        subcontent: "Delivering excellence, one line of code at a time."
    },
    {
        icon: "faCompass",
        title: "About Me",
        content: "Driven by innovation and excellence. My journey as a back-end developer pushes the boundaries of what’s possible.",
        subtitle: "Passionate About Progress",
        subcontent: "Creating innovative and robust solutions."
    },
    {
        icon: "faPeopleArrows",
        title: "Why Work With Me",
        content: "Building startups is challenging. A supportive team is crucial. I am dedicated and constantly improving.",
        subtitle: "Team Spirit",
        subcontent: "Accepting people as they are and driving towards common goals."
    },
    {
        icon: "faBinoculars",
        title: "Vision",
        content: "I envision assembling a team to create a groundbreaking product. A team that excels in funding, development, and sales.",
        subtitle: "Diverse Skill Set",
        subcontent: "Versatile in both back-end and front-end projects."
    },
    {
        icon: "faCogs",
        title: "Innovation in Automation",
        content: "Developing platforms that push the boundaries of what's possible with automation.",
        subtitle: "Cutting-Edge Payment Solutions",
        subcontent: "Implemented systems like Klarna, MobilePay, and ApplePay with advanced features."
    },
    {
        icon: "faProjectDiagram",
        title: "My Work",
        content: "Showcasing pioneering projects that highlight my skills in robust back-end systems.",
        subtitle: "Showcase of Excellence",
        subcontent: "Delivering high-quality, reliable results."
    },
    {
        icon: "faEnvelopeOpenText",
        title: "Contact",
        content: "Let’s build the future. If you need a back-end developer who delivers unmatched precision and quality, let's connect.",
        subtitle: "Join Forces for Innovation",
        subcontent: "Together, setting new standards in tech."
    },
    {
        icon: "faInfinity",
        title: "Innovating the Impossible",
        content: "Breaking boundaries and setting new standards in back-end development.",
        subtitle: "Transforming Digital Landscapes",
        subcontent: "Creating scalable and efficient solutions."
    },
    {
        icon: "faBrain",
        title: "Technical Mastery",
        content: "Expert in multiple languages and frameworks, delivering unparalleled solutions.",
        subtitle: "Full-Stack Prowess",
        subcontent: "Specializing in back-end, excelling in front-end integration."
    },
    {
        icon: "faPuzzlePiece",
        title: "Beyond the Code",
        content: "Delivering strategic insights and innovative problem-solving approaches.",
        subtitle: "Cutting-Edge Solutions",
        subcontent: "Utilizing the latest tech for next-gen solutions."
    },
    {
        icon: "faBuilding",
        title: "Enterprise-Grade Solutions",
        content: "Providing scalable and secure solutions, ensuring reliability and performance.",
        subtitle: "Data-Driven Decisions",
        subcontent: "Leveraging analytics to optimize development."
    },
    {
        icon: "faBolt",
        title: "Agile and Adaptive",
        content: "Using agile methods for flexibility and rapid iteration.",
        subtitle: "Continuous Improvement",
        subcontent: "Refining skills to deliver the best outcomes."
    },
    {
        icon: "faGlobe",
        title: "Global Perspective",
        content: "Experience with international teams and projects, bringing a global view to development.",
        subtitle: "Cross-Industry Expertise",
        subcontent: "Diverse experience bringing unique insights."
    },
    {
        icon: "faMountain",
        title: "Innovative Solutions for Complex Problems",
        content: "Thriving on challenges and delivering unique solutions.",
        subtitle: "High-Performance Systems",
        subcontent: "Designing systems for speed, efficiency, and reliability."
    },
    {
        icon: "faBullseye",
        title: "Client-Centric Approach",
        content: "Focusing on client needs and delivering tailored solutions.",
        subtitle: "End-to-End Development",
        subcontent: "Handling all aspects from concept to deployment."
    },
    {
        icon: "faTrophy",
        title: "Proven Track Record",
        content: "Delivering projects on time and within budget, focusing on quality.",
        subtitle: "Strategic Vision",
        subcontent: "Aligning development with business goals."
    },
    {
        icon: "faForward",
        title: "Future-Ready Solutions",
        content: "Building solutions prepared for future challenges.",
        subtitle: "Ethical Coding",
        subcontent: "Committed to ethical coding practices."
    },
    {
        icon: "faHandsHelping",
        title: "Community Contribution",
        content: "Contributing to open-source projects and the developer community.",
        subtitle: "Mentorship and Leadership",
        subcontent: "Guiding and mentoring junior developers."
    },
    {
        icon: "faLeaf",
        title: "Eco-Friendly Development",
        content: "Implementing sustainable practices in coding to minimize environmental impact.",
        subtitle: "Innovative Frameworks",
        subcontent: "Using cutting-edge frameworks for robust solutions."
    }
    */}
];
