import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import gsap from 'gsap';

class Modal extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            gsap.to('.modal-overlay', {
                opacity: 1,
                pointerEvents: 'all',
                duration: 0.5,
                ease: "power3.out"
            });
            gsap.to('.modal', {
                opacity: 1,
                y: 0,
                duration: 0.5,
                ease: "power3.out"
            });
        }
    }

    handleClose = () => {
        gsap.to('.modal', {
            opacity: 0,
            y: 50,
            duration: 0.5,
            ease: "power3.out",
            onComplete: () => this.props.onClose()
        });
        gsap.to('.modal-overlay', {
            opacity: 0,
            pointerEvents: 'none',
            duration: 0.5,
            ease: "power3.out"
        });
    };

    render() {
        const { visible, content } = this.props;

        if (!visible) return null;

        return (
            <div className="modal-overlay" onClick={this.handleClose}>
                <div className="modal" onClick={(e) => e.stopPropagation()}>
                    <FontAwesomeIcon icon={faEnvelope} className="modal-close" onClick={this.handleClose} />
                    <h3>{content.title}</h3>
                    <p>{content.content}</p>
                    {content.subtitle && <h4>{content.subtitle}</h4>}
                    {content.subcontent && <p>{content.subcontent}</p>}
                </div>
            </div>
        );
    }
}

export default Modal;
