// src/buttonAnimations.js
import { gsap } from 'gsap';

export const setupButtonAnimations = (selector) => {
    const buttons = document.querySelectorAll(selector);
    buttons.forEach(button => {
        button.addEventListener('mouseenter', () => {
            gsap.to(button, { scale: 1.1, duration: 0.2 });
        });
        button.addEventListener('mouseleave', () => {
            gsap.to(button, { scale: 1.0, duration: 0.2 });
        });
    });
};
